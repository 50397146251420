<template>
  <div class="container-fluid py-3">
    <div class="row mb-3">
      <div class="col-4 mb-4">
        <img :src="product.image" :alt="product.name" class="img-fluid">
      </div>
      <div class="col-8 col-md-4 mb-">
        <b-form @submit.prevent="buy()">
          <h5>{{ product.name }}</h5>
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label for="duration">{{ $t('duration') }}</label>
                <select name="duration" id="duration" class="form-control" v-model="duration" v-validate="'required'" :state="validateState('duration')">
                  <option :value="3">3 {{ $tc('month', 2) | toCapitalize }}</option>
                  <option :value="6">6 {{ $tc('month', 2) | toCapitalize }}</option>
                  <option :value="12">12 {{ $tc('month', 2) | toCapitalize }}</option>
                </select>
                <span class="error-inputs">{{ errors.first('duration') }}</span>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label for="price">{{ $t('price') }}</label>
                <input type="text" name="price" id="price" class="form-control" v-model="price" readonly v-validate="'required'" :state="validateState('price')">
                <span class="error-inputs">{{ errors.first('price') }}</span>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group" v-if="product.type === 'BT' || product.type === 'BD'">
                <label for="tournament">{{ $t('tournaments') }}</label>
                <select name="tournament" id="tournament" class="form-control" v-model="tournamentId" v-validate="'required'" :state="validateState('tournament')">
                  <option v-for="tournament in tournaments" :key="tournament.id" :value="tournament.id">{{ tournament.name }}</option>
                </select>
                <span class="error-inputs">{{ errors.first('tournament') }}</span>
              </div>
              <div class="form-group" v-if="product.type === 'BD'">
                <label for="divisions">{{ $t('divisions') }}</label>
                <select name="divisions" id="divisions" class="form-control" v-model="divisionId" v-validate="'required'" :state="validateState('divisions')">
                  <option v-for="division in divisionsFilter" :key="division.id" :value="division.id">{{ division.name }}</option>
                </select>
                <span class="error-inputs">{{ errors.first('divisions') }}</span>
              </div>
              <div class="form-group" v-if="product.type === 'BE'">
                <label for="teams">{{ $t('teams') }}</label>
                <select name="teams" id="teams" class="form-control" v-model="teamId" v-validate="'required'" :state="validateState('teams')">
                  <option v-for="team in teams" :key="team.id" :value="team.id">{{ team.name }}</option>
                </select>
                <span class="error-inputs">{{ errors.first('teams') }}</span>
              </div>
              <b-button type="submit" block variant="primary" :disabled="!isAuthenticated">{{ $t('toBuy') }}</b-button>
            </div>
          </div>
        </b-form>
      </div>
      <div class="col-12 col-md-4">
        <h4>{{ $t('relatedProducts') }}</h4>
        <hr class="mt-0">
        <div class="row">
          <div class="col-4" v-for="related in relateds" :key="related.slug">
            <router-link :to="{ name: 'ShopProduct', params: { lang: lang, console: console, slug: related.slug } }">
              <div class="product-related">
                <figure>
                  <img :src="related.image" :alt="related.name" class="img-fluid">
                </figure>
                <div class="product__related-name">
                  <h4>{{ related.name }}</h4>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h4>{{ $tc('category', 2) | toCapitalize }}</h4>
        <hr class="mt-0">
      </div>
      <div class="col-4 col-sm-3 col-md-2" v-for="category in categories" :key="category.id">
        <router-link :to="{ name: 'ShopCategory', params: { lang: lang, console: console, slug: category.slug } }">
          <category-section :category="category" />
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import CategorySection from  './partials/Category'

export default {
  components: {
    CategorySection
  },
  data () {
    return {
      categories: [],
      product: {},
      isOwner: false,
      duration: 3,
      price: 0,
      relateds: [],
      tournaments: [],
      divisions: [],
      divisionsFilter: [],
      slug: this.$route.params.slug,
      tournamentId: 0,
      divisionId: 0,
      teams: [],
      teamId: 0
    }
  },
  computed: {
    ...mapGetters([
      'console',
      'lang',
      'user',
      'isAuthenticated'
    ])
  },
  watch: {
    '$route' () {
      this.slug = this.$route.params.slug
      this.fetchData()
    },
    tournamentId (value) {
      this.divisionsFilter = this.divisions.filter(d => {
        return d.tournament_id === value
      })
    },
    duration (value) {
      if (value === 3) {
        this.price = this.product.price_1
      } else if (value === 6) {
        this.price = this.product.price_2
      } else if (value === 12) {
        this.price = this.product.price_3
      }
    }
  },
  mounted () {
    this.fetchData()
  },
  methods: {
     buy () {
      this.$validator.validate().then(result => {
        if (result) {
          const payload = {
            product_id: this.product.id,
            duration: this.duration
          }
          if (this.product.type === 'BT' || this.product.type === 'BD') {
            payload.tournament_id = this.tournamentId
          }
          if (this.product.type === 'BD') {
            payload.division_id = this.divisionId
          }
          if (this.product.type === 'BE') {
            payload.team_id = this.teamId
          }
          const path = `auth/${this.lang}/console/${this.console}/shop/products/${this.slug}`
          this.$axios.post(path, payload).then(response => {
            this.$store.dispatch('SET_UPDATE_MY_MONEY', response.data.money)
            this.$toastr.success(response.data.message, window.TITLE_SUCCESS)
          })
        } else {
          this.showValidationsErrors(this.errors.items)
        }
      })
    },
    fetchData () {
      const path = `${this.lang}/console/${this.console}/shop/products/${this.slug}`
      this.$axios.get(path).then(response => {
        const data = response.data
        this.categories = data.categories
        this.relateds = data.related
        this.product = data.product
        this.price = this.product.price_1
        this.isOwner = data.owner
        if (this.product.type === 'BT') {
          this.tournaments = data.entities
        }
        if (this.product.type === 'BD') {
          this.tournaments = data.entities.tournaments
          this.divisions = data.entities.divisions
        }
        if (this.product.type === 'BE') {
          this.teams = data.entities
        }
      })
    }
  }
}
</script>
